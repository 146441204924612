.HeroSection {
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: var(--gradient);
  padding: var(--ext-padding);

  &__canvas {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;

    &__scrollIcon {
      z-index: 2;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid var(--grey);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 15px !important;
      }
    }
  }
}
