@import 'mixins.scss';
.Hamburger {
  display: flex;
  align-items: center;
  position: absolute;
  top: var(--tot-padding);
  right: var(--tot-padding);
  height: var(--header-height);
  min-height: 20px;

  p {
    margin-right: 10px;
    margin-bottom: 0;
    color: var(--darkGrey);
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: var(--header-height);
    height: 100%;
    min-width: 20px;
    cursor: pointer;

    span {
      height: 3px;
      width: 100%;

      &:nth-child(1) {
        background: var(--blue);
      }
      &:nth-child(2) {
        background: #6462b8;
      }
      &:nth-child(3) {
        background: var(--purple);
      }
    }
  }
}
