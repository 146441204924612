@import 'mixins.scss';
.button {
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
  background: transparent;
  border: 1px solid #ffffff;
  width: fit-content;
  padding: 10px 30px;
  text-decoration: none;
  z-index: 2;

  transition: all 0.2s ease-in-out;
  &:hover {
    background: #ffffff;
    border: 1px solid var(--purple);
    color: var(--purple);
  }
}

.GradientButton {
  margin-top: 20px;
  position: relative;
  background: var(--gradient);
  padding: 5px 15px;
  text-decoration: none;
  z-index: 2;

  font-weight: bold;
  width: fit-content;
  display: flex;
  align-items: center;

  p {
    position: relative;
    font-size: var(--fz-xs);
    font-weight: var(--fw-bold);
    z-index: 2;
    margin: 0 !important;
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.3s;
  }

  img {
    width: 15px;
    position: relative;
    z-index: 2;
    margin-left: 10px;
    transition: 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: #fff;
    z-index: 1;
    opacity: 1;
    transition: 0.3s;
  }

  &:hover {
    p {
      background: #fff;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:after {
      opacity: 0;
    }

    img {
      filter: brightness(10);
    }
  }

  @include small {
    p {
      font-size: var(--fz-sm);
    }

    img {
      width: var(--fz-sm);
    }
  }
}
