@import 'mixins.scss';
.ProjectTile {
  padding-top: 30px;
  /*height: 35vw;*/
  max-height: 550px;
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow: hidden;
  transition: 0.3s;

  &:hover {
    transform: translateY(-10px);
  }

  @include qhd {
    max-height: 50vh;
  }

  h3 {
    padding: 0 25px;
    color: #fff;
    font-size: var(--fz-md);
    font-weight: var(--fw-bold);
    margin-bottom: 20px;
  }

  p {
    padding: 0 25px;
    color: #fff;
    line-height: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0 25px;

    &__tag {
      width: fit-content;
      border-radius: 3px;
      margin: 0 10px 10px 0;
      color: #fff;
      font-weight: var(--fw-bold);
      padding: 5px 8px;
      font-size: var(--fz-xs);
      white-space: nowrap;
    }
  }

  &__imageWrapper {
    position: relative;
    margin-top: auto;
    flex: 1;

    min-height: 350px;

    @include qhd {
      min-height: 500px;
      max-height: 50vh;
    }

    div {
      display: block;
      overflow: hidden;
      position: absolute;
      inset: 0px;
      box-sizing: border-box;
      margin: 0px;

      picture {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;
        }
      }
    }
  }

  @include small {
    margin-bottom: 5vh !important;
    max-height: none;

    &__imageWrapper {
      min-height: 85vw;
      overflow: hidden;

      & > div {
        position: absolute;
        $decal: 20vw;
        $diff: calc(var(--ext-padding) * 2 + var(--in-padding) * 2);
        top: 0;
        left: -$decal;
        right: -$decal;
        bottom: -$decal;
        width: 100vw + $decal * 2 - $diff;

        picture {
          img {
            object-fit: contain;
          }
        }
      }
    }
  }
}
