@import 'mixins.scss';
.Footer {
  background: var(--gradient);
  padding: 50px calc(2vw + 50px);
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @include fhd {
    padding: 50px calc(calc(100% - 1400px) / 2);
    justify-content: space-between;
  }

  @include qhd {
    padding: 50px calc(calc(100% - 1600px) / 2);
    justify-content: space-between;
  }

  &__mask {
    pointer-events: none;
    mix-blend-mode: soft-light;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url('/img/footer-mask.webp');
    background-size: cover;
  }

  &__symbol {
    flex: 1;
    max-width: 600px;
    max-height: 600px;
    filter: invert(1);
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 430px;

    h2 {
      color: #fff;
      font-size: var(--fz-lg);
      font-family: 'HastaGrotesk';
    }

    img {
      width: 105px;
    }

    a,
    p {
      color: #fff;
      opacity: 0.8;
      line-height: 20px;
      text-align: justify;
      text-align-last: left;
      margin: 0;
      text-decoration: none;
    }

    a {
      margin-top: 30px;
    }

    &__smallDivider {
      height: 3px;
      width: 70px;
      background: #fff;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &__divider {
      height: 1px;
      width: 100%;
      background: #fff;
      margin-top: 30px;
      margin-bottom: 30px;
      opacity: 0.3;
    }

    &__info {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      img {
        width: 30px;
        margin-right: 10px;
      }

      a {
        margin-top: 0;
      }
    }
  }

  @include small {
    flex-direction: column;
    padding: calc(var(--in-padding) + var(--ext-padding));
    padding-top: 50px;

    &__mask {
      background-image: url('/img/footer-mask-vertical.webp');
    }
    &__symbol {
      width: calc(
        100vw - var(--in-padding) - var(--ext-padding) - var(--in-padding) -
          var(--ext-padding)
      );
      height: calc(
        100vw - var(--in-padding) - var(--ext-padding) - var(--in-padding) -
          var(--ext-padding)
      );
    }

    &__content {
      width: 100%;
    }
  }
}

.subFooter {
  z-index: 4;
  position: relative;
  height: 180px;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: scale(2);
    opacity: 20%;
  }

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 50px;
      margin-bottom: 15px;
    }
  }
}
