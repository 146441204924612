@import 'mixins.scss';
.Experience {
  opacity: 0;
  position: fixed;
  inset: 0;
  z-index: 3;
  padding: calc(var(--ext-padding) + var(--in-padding));
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--purple);

  &__memo {
    opacity: 0;
    letter-spacing: 3rem;
    position: absolute;
    left: 50%;
    top: calc(50% - 20px);
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 12vw;
    font-weight: var(--fw-bold);
    font-family: 'Geometos';
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.7);
    color: var(--purple);

    @include small {
      top: 50%;
      z-index: -1;
      writing-mode: vertical-rl;
      text-orientation: upright;
      font-size: 9vw;
    }

    @media (max-width: 600px) {
      font-size: 12vw;
    }

    @media (max-width: 400px) {
      font-size: 15vw;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__grid {
      color: #fff;

      position: relative;

      display: grid;
      grid-template-columns: min-content;
      grid-template-rows: repeat(3, min-content);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }

    h2 {
      font-family: var(--ff-title);
      font-size: var(--fz-lg);
      font-weight: var(--fw-bold);
      text-align: center;
      margin-bottom: 20px;
      width: fit-content;
      white-space: nowrap;
    }

    p {
      text-align: justify;
      text-align-last: center;
      font-size: var(--fz-sm);
      line-height: 20px;
      opacity: 0.7;
    }

    &__ctxbutton {
      display: flex;
      justify-content: center;
    }

    @include small {
      padding: var(--tot-padding);

      &__grid {
        width: 100%;
        grid-template-columns: 1fr;
      }

      h2 {
        margin: 0 auto 20px auto;
        white-space: normal;
      }

      p {
        text-align: center;
      }
    }
  }
}
