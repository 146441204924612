.horizontal {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;

  /* Disable for Chrome*/
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* Disable for Firefox*/
}
