@import 'mixins.scss';
.Projets {
  h3 {
    font-family: var(--ff-title);
    font-weight: var(--fw-bold);
    color: #ffffff;
  }

  p:not(a p) {
    padding: 0 var(--layout-padding);
  }

  @include normalmid {
    [id='goto-realisations-mobile'] {
      display: none;
    }
  }

  @include mid {
    padding: var(--separ-padding) 0;

    [id='goto-realisations-mobile'] {
      display: none;
    }
  }

  @include small {
    padding: 6vh 0;

    [id='goto-realisations-mobile'] {
      margin-bottom: 0 !important;
    }
    [id='goto-realisations-desktop'] {
      display: none;
    }

    & > div {
      padding: 0;
    }

    p:not(a p) {
      padding: 0 var(--tot-padding);
    }

    &__title {
      h2 {
        flex: 1;
        margin-right: 0 !important;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
    }
  }

  padding: var(--separ-padding) 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 4;
  background: #ffffff;
  transform: none !important;

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--layout-padding);

    h2 {
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      font-family: var(--ff-title);
      font-weight: var(--fw-bold);
      font-size: var(--fz-xxl);
      margin-bottom: var(--tot-padding);
      margin-right: 30px;
    }

    a {
      text-decoration: none;
      margin: 0 !important;
      margin-bottom: var(--tot-padding) !important;
    }
  }
  h2,
  span {
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: var(--ff-title);
    font-weight: var(--fw-bold);
    font-size: var(--fz-xxl);

    @include mid {
      font-size: var(--fz-xl);
    }

    @include small {
      text-align: center;
      font-size: var(--fz-xl);
    }
  }

  p {
    margin: 0;
    color: var(--darkGrey);
    line-height: 20px;
    @include small {
      text-align: center;
    }
  }

  &__list {
    padding: var(--tot-padding) 0;
    display: flex;

    &__padding {
      padding-right: var(--layout-padding);
    }

    &__ctx {
      height: 542px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      gap: 3vw;

      a div {
        max-height: unset;
      }

      a div div:last-child {
        min-height: unset;
      }

      a > div > div:first-child div {
        margin-bottom: 0;
      }

      p {
        color: #ffffff;
        letter-spacing: normal;
        margin-bottom: 20px;
      }

      a {
        min-width: 316px;
      }

      @include small {
        a {
          min-width: auto;
        }
      }
    }
  }

  @include small {
    &__list {
      flex-direction: column;
      padding: 0 var(--tot-padding);
      margin-top: 50px;

      &__ctx {
        flex-direction: column;

        a {
          width: 100%;
        }

        p {
          text-align: left;
          color: #ffffff;
        }

        a > div > div:last-child {
          min-height: 350px;
        }
      }
    }
  }
}
