.Header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: calc(var(--header-height) + var(--tot-padding) * 2);
  width: 100%;

  nav {
    width: 100%;
    height: 100%;
  }
}

.Nav {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  height: calc(var(--header-height) + var(--tot-padding) * 2);
  width: 100%;
}
