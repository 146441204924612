@import 'mixins.scss';
.AnimatedMenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
  transition: none;
  flex-direction: column;

  &__in,
  &__out {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__out {
    display: none;
  }

  &__header {
    position: absolute;
    top: calc(var(--ext-padding) + var(--in-padding));
    right: calc(var(--ext-padding) + var(--in-padding));
    z-index: 1;
    opacity: 0;
    transform: translate(10px, -10px);

    img {
      transform: rotate(45deg);
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }

  &__content {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    &__links {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      a {
        position: relative;
        display: block;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
      }

      $linkmarge: 25px 50px;
      a[class*='link'] {
        padding: $linkmarge;
        font-family: var(--ff-title);
        font-size: var(--fz-lg);
        font-weight: var(--fw-bold);
        transform: translateX(-150%);

        &[class*='selected'] {
          .AnimatedMenu__content__links__bg {
            right: 50%;
            transform: translateX(0%);
          }
        }
        &:hover {
          .AnimatedMenu__content__links__bg {
            right: 0;
            transform: translateX(0%);
          }
        }
      }

      &__bg {
        position: absolute;
        inset: 0;
        transform: translateX(-100%);
        pointer-events: none;

        transition: all 0.3s ease-in-out;

        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.3),
          rgba(255, 255, 255, 0)
        );
      }

      &__around {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        a {
          text-decoration: none;
          margin: 0 0 $linkmarge;
          opacity: 0;

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }

    &__image {
      height: 100%;
      flex: 1;
      position: relative;
      opacity: 0;
      mix-blend-mode: soft-light !important;
      will-change: opacity;

      picture {
        height: 100%;
        width: 100%;
        position: absolute;
        inset: 0;
        opacity: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;

          @media (max-width: 1200px) {
            max-width: none !important;
            max-height: none !important;
            width: auto !important;
            object-fit: cover;
            left: calc(-300px + 10vw) !important;
          }
        }
      }

      // div {
      //   height: 100%;
      //   position: absolute !important;
      //   left: 0;
      //   right: 0;
      //   top: 0;
      //   bottom: 0;

      //   @media (max-width: 1200px) {
      //     overflow: visible !important;
      //   }
      // }
    }
  }

  @include small {
    &__header {
      top: var(--tot-padding);
      right: var(--tot-padding);

      img {
        width: 6vh;
        height: 6vh;
      }
    }

    &__content {
      &__links {
        width: 100%;
      }

      &__image {
        display: none;
      }
    }
  }
}
