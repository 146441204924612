@import 'mixins.scss';
.FilArianne {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: var(--tot-padding);
  left: var(--tot-padding);

  &_logo {
    height: var(--header-height);
    min-height: 25px;

    img {
      height: 100%;
    }
  }

  p {
    color: var(--darkGrey);
    margin: 0 0 -2px 10px;

    a {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  @include small {
    p {
      display: none;
    }
  }
}
