@import 'mixins.scss';
$mediawidth: 1300px;

.Technos {
  position: relative;
  padding: var(--separ-padding) 0;
  overflow: hidden;

  z-index: 4;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__table {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__content {
    h2 {
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: var(--ff-title);
      font-weight: var(--fw-bold);
      font-size: var(--fz-xxl);
      margin-bottom: 30px;
      line-height: normal;
    }

    p {
      color: var(--darkGrey);
      line-height: 20px;
    }
  }

  @media only screen and (width <= $mediawidth) {
    :global(.layout) {
      flex-direction: column;

      & > *:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  @include small {
    padding: 6vh 0;

    &__table {
      margin-bottom: 30px;
      img {
        width: calc(
          100vw - var(--in-padding) - var(--ext-padding) - var(--in-padding) -
            var(--ext-padding)
        );
      }
    }

    &__content {
      h2,
      p {
        text-align: center;
      }
      h2 {
        font-size: var(--fz-xl);
      }
    }
  }
}
