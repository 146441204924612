@import 'mixins.scss';
$heading-fz: 4.5vw;
$heading-lh: 6.5vw;

$heading-fz-mobile: 9vw;
$heading-lh-mobile: 17vw;

.HeroHeading {
  /* fix for firefox */
  @-moz-document url-prefix() {
    display: table;
  }

  font-family: var(--ff-title);
  font-weight: var(--fw-bold);
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
  font-size: $heading-fz;
  line-height: $heading-lh;
  text-align: center;
  width: 100%;
  background: var(--gradient-title);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}

.Overlay {
  font-family: var(--ff-title);
  font-weight: var(--fw-bold);
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
  font-size: $heading-fz;
  line-height: $heading-lh;
  text-align: center;
  width: 100%;
  color: white;
  mix-blend-mode: overlay;
  z-index: 3;
  will-change: opacity;
}

@include small {
  .HeroHeading,
  .Overlay {
    width: 80vw;
    font-size: $heading-fz-mobile;
    line-height: $heading-lh-mobile;
  }
}
