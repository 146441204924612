@import 'mixins.scss';
.Missions {
  height: 400vh;
  width: 100%;
  max-width: 100%;
  position: sticky;
  z-index: 2;
  background: var(--purple);

  &__content {
    clip-path: inset(0 0 0 0);

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    &__gradient {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      width: 100%;
      height: 100%;
      padding: var(--ext-padding);
      justify-content: center;
      align-items: center;

      &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: var(--gradient);
      }

      &__container {
        position: relative;
        width: 100%;
        height: 100%;

        &__missionBlock,
        &__valeurBlock {
          position: absolute;
          width: 380px;
          color: #fff;
          z-index: 5;

          display: flex;
          flex-direction: column;
          justify-content: center;

          @include small {
            position: relative;
            width: 100%;
            padding: 0 var(--in-padding);
            flex: 1;
          }

          h2 {
            font-family: var(--ff-title);
            font-weight: var(--fw-bold);
            font-size: var(--fz-lg);
            margin-bottom: 10px;
          }

          p {
            opacity: 0.8;
            line-height: 20px;
          }
        }
        &__missionBlock {
          @include normal {
            top: 25%;
            left: 5%;
          }

          @include small {
            color: #fff;
            text-align: center;
          }
        }

        &__valeurBlock {
          @include normal {
            top: 70%;
            right: 5%;
            text-align: right;
          }

          @include small {
            color: #fff;
            text-align: center;
          }
        }

        &__missionLine,
        &__valeursLine {
          position: absolute;
          z-index: 5;

          @include small {
            display: none;
          }
        }

        &__missionLine {
          top: 17px;
          left: calc(5% + 290px);
          width: 15vw;
        }

        &__valeursLine {
          top: 9px;
          right: calc(5% + 280px);
          width: 10vw;
        }
      }
    }
  }
}

.move {
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 0;
  right: 0;
  will-change: top;
  padding-top: calc(3vh + var(--tot-padding) + var(--tot-padding));

  @include small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    picture {
      opacity: 0.3;
    }
  }
}
