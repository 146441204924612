@import 'mixins.scss';
.Services {
  @include normal {
    padding: var(--separ-padding) 0;

    [id='goto-services-mobile'] {
      display: none;
    }
  }

  width: 100%;
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transform: none !important;
  background: #fafafa;

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--layout-padding);

    h2 {
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      font-family: var(--ff-title);
      font-weight: var(--fw-bold);
      font-size: var(--fz-xxl);
      margin-bottom: var(--tot-padding);
      margin-right: 30px;
    }

    a {
      text-decoration: none;
      margin: 0 !important;
      margin-bottom: var(--tot-padding) !important;
    }
  }

  &__text {
    color: var(--darkGrey);
    line-height: 20px;
    padding: 0 var(--layout-padding);
  }

  &__list {
    margin-top: 10px;
    padding: var(--tot-padding) 0;
    display: flex;

    &__padding {
      padding-right: var(--layout-padding);
    }

    &__ctx {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }

    &__single {
      text-decoration: none;
      padding: 30px 20px;
      background: var(--gradient);
      background-color: var(--purple);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      transition: 0.5s;
      cursor: pointer;

      width: 290px;
      min-width: 290px;

      @include normal {
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0px;
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        filter: brightness(10);

        img {
          width: 70px;
          margin-bottom: 20px;
        }

        p {
          color: #fff;
          margin-bottom: 20px;
          text-align: center;
          line-height: 20px;
        }
      }

      &__more {
        z-index: 1;
        width: 40px;
        height: 40px;
        border: 1px solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          filter: brightness(10);
          width: 13px;
        }
      }
    }

    &__singleActive {
      &:before {
        position: absolute;
        content: '';
        top: 5px;
        bottom: 5px;
        left: 5px;
        right: 5px;
        background: #fff;
        z-index: 0;
      }
      box-shadow: 0 10px 15px 0 rgb(102 54 149 / 50%);
      transform: translateY(-10px);

      div {
        filter: none !important;

        p {
          color: var(--purple);
        }
      }

      &__moreActive {
        z-index: 1;
        width: 40px;
        height: 40px;
        background: var(--gradient);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          filter: brightness(10);
          width: 20px;
        }
      }
    }
  }

  @include small {
    padding: 6vh 0;

    [id='goto-services-desktop'] {
      display: none;
    }

    :global(.layout) {
      align-items: center;
    }

    &__title {
      flex-direction: column;
      padding: 0;

      h2,
      p {
        text-align: center;
        margin-right: 0;
      }
      h2 {
        font-size: var(--fz-xl);
      }
    }

    &__list {
      flex-direction: column;

      &__ctx {
        flex-direction: column;
      }

      &__single {
        width: auto;
        margin-bottom: var(--tot-padding);
      }
    }

    p {
      text-align: center;
    }
  }
}
